import api from './api';

const handleSendAsqAnswers = async (
  childId,
  parentId,
  childFirstName,
  childLastName,
  answers,
  roomType,
  ageGroup
) => {
  try {
    const response = await api.post('/answer/asqanswers', {
      childId: childId,
      parentId: parentId,
      childFirstName: childFirstName,
      childLastName: childLastName,
      answers: answers,
      roomType: roomType,
      ageGroup: ageGroup,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
export { handleSendAsqAnswers };
