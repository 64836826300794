import React, { useState, useEffect } from 'react';
import RadioButtons from '../../../../components/RadioButtonMenka'; // Assuming RadioButton component name
import { Button } from 'flowbite-react';
import Arrow from '../../../../assets/arrow.svg';
import attachmentTest from '../../../../data/attachmentTest';
import parentingTest from '../../../../data/parentingTests';
import { useParams } from 'react-router-dom';

const answers = [
  'کاملا موافقم',
  'موافقم',
  'تقریبا مخالفم ',
  'مخالفم',
  'کاملا مخالفم',
];

function SimpleQIndex() {
  const { id } = useParams();
  const [questions, setQuestions] = useState(
    id === '1' ? parentingTest : attachmentTest
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(questions?.length).fill('')
  );
  const [completedQuestions, setCompletedQuestions] = useState([]);

  useEffect(() => {
    if (currentStep === questions.length - 1) {
      console.log('All questions answered');
      // All questions answered, save to localStorage
      localStorage.setItem(
        id === '1' ? 'parentingAnswers' : 'attachmentAnswers',
        JSON.stringify(completedQuestions)
      );
    }
  }, [currentStep, completedQuestions, questions.length]);

  const handleNextStep = () => {
    if (currentStep < questions.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      // Last question, save answer and navigate
      const updatedCompletedQuestions = [
        ...completedQuestions,
        {
          question: questions[currentStep].question,
          answer: selectedAnswers[currentStep],
        },
      ];
      setCompletedQuestions(updatedCompletedQuestions);
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    {
      currentStep === questions.length - 1 &&
        window.location.replace(`/dashboard/initialtests/${id}/ConfirmPage`);
    }
  }, [currentStep]);

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleAnswerChange = (index, answer) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[index] = answer;
    setSelectedAnswers(updatedAnswers);
    const updatedCompletedQuestions = completedQuestions.slice();
    updatedCompletedQuestions[index] = {
      question: questions[index].question,
      answer: answer,
    };
    setCompletedQuestions(updatedCompletedQuestions);
  };

  return (
    <div className="flex flex-col w-full h-screen bg-gray-100 p-5">
      <div
        onClick={() => {
          window.location.href = '/dashboard/questionnaire';
        }}
        className="mr-3 text-sm cursor-pointer flex flex-row">
        <img src={Arrow} alt="Arrow" className="inline-block w-8 h-8" />
        <h1 className="text-md font-bold text-gray-900 dark:text-white mb-5 mt-2 ml-5">
          بازگشت به صفحه اصلی
        </h1>
      </div>
      <hr />
      <div className="flex flex-row w-full justify-start items-center mt-8">
        {currentStep + 1}/{questions.length}
      </div>
      <div className="flex flex-col w-full h-full items-center justify-center">
        <div className="text-lg font-bold text-gray-900 dark:text-white mb-3">
          {currentStep < questions.length && questions[currentStep].question}
        </div>

        <div className="text-sm font-normal text-gray-900 dark:text-white mb-3">
          {answers.map((answer, index) => (
            <RadioButtons
              key={index}
              style={'row'}
              value={answer}
              checked={selectedAnswers[currentStep] === answer}
              onChange={() => handleAnswerChange(currentStep, answer)}
              label={answer}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-row w-1/4 justify-center gap-4 mt-20">
        <Button
          onClick={handlePrevStep}
          color="light"
          disabled={currentStep === 0}>
          قبلی
        </Button>
        <Button
          onClick={handleNextStep}
          disabled={selectedAnswers[currentStep] === ''}>
          {currentStep === questions.length - 1 ? 'اتمام' : 'بعدی'}
        </Button>
      </div>
    </div>
  );
}

export default SimpleQIndex;
