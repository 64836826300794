import { Button } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import api from '../../api/api';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/userSlice';
import { convertToEnglishNumber } from '../../api/convertNumbers';

function CodeInput({ phone, setStatus, setErrorMessage }) {
  const [code, setCode] = useState(''); // Array to hold individual input values\

  const inputRefs = useRef(Array(4).fill(null)); // Array of refs for inputs
  const dispatch = useDispatch();
  const [currentInputIndex, setCurrentInputIndex] = useState(0);

  const handleInputChange = (e, index) => {
    // Restrict input to numbers
    const newDigit = convertToEnglishNumber(e.target.value);

    setCode(newDigit);
    // Update code array at the specific index
    // setCode((prevCode) => {
    //   const updatedCode = [...prevCode];
    //   updatedCode[index] = newDigit;
    //   return updatedCode;
    // });

    // Focus on the previous input if current input has a value
    // if (newDigit && index > 0) {
    //   inputRefs.current[index - 1].focus();
    // } else if (newDigit && index === 0) {
    //   setCurrentInputIndex(1); // Move to the next input
    // }
  };

  // useEffect(() => {
  //   if (inputRefs.current[currentInputIndex]) {
  //     inputRefs.current[currentInputIndex].focus();
  //   }
  // }, [currentInputIndex]);

  const handleVerify = async () => {
    setStatus('pending');
    // const codeValue = code.join('');
    //const codeValue = code.slice().reverse().join('');

    const result = await api.post('api/verify/', {
      phoneNumber: phone,
      verificationCode: code,
    });
    console.log('result', result);

    if (result.data.status !== 'error') {
      setStatus('success');
      dispatch(
        setUser({
          userId: result.data.user.userId,
          name: result.data.user.name,
          phone: phone,
          isLoggedIn: true,
        })
      );
      window.location.href = '/dashboard';
      console.log('result', result);
    } else {
      console.log('error');
      setStatus('error');
      setErrorMessage('کد وارد شده اشتباه است.');
    }
  };

  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">تایید شماره موبایل</h1>
      <p
        className="text-gray-600 mb-4 text-center w-1/2 text-md
     ">
        کد ارسال شده به شماره {phone} را وارد کنید.
      </p>
      <span
        onClick={() => setStatus('idle')}
        className="text-xs text-blue cursor-pointer hover:text-primary justify-center mb-3">
        ویرایش شماره{' '}
      </span>
      <div className="mb-4  m-auto">
        <input
          //ref={(el) => (inputRefs.current[index] = el)}
          onChange={(e) => handleInputChange(e)}
          type="text"
          maxLength="4"
          id="code1"
          name="code1"
          className="mt-4 w-48 ml-2 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
          autoComplete="off"
          //value={digit}
        />
        {/* {code.map((digit, index) => (
          <div key={index} className="inline-block">
            <div className="flex justify-between" dir="ltr">
              <input
                //ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleInputChange(e, index)}
                type="text"
                maxLength="1"
                id={`code${index + 1}`}
                name={`code${index + 1}`}
                className="mt-4 w-12 ml-2 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
                autoComplete="off"
                value={digit}
              />
            </div>
          </div>
        ))} */}
      </div>

      <Button
        onClick={handleVerify}
        style={{ background: '#3F5F90' }}
        className="w-1/2 py-2 text-white rounded-md mt-4 bg-[#3F5F90]">
        تایید کد
      </Button>
    </>
  );
}

export default CodeInput;
