import ageGroups from '../data/ageGroups';

const handleCalculateAge = async (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);

  const years = today.getFullYear() - birthDate.getFullYear();
  const months = today.getMonth() - birthDate.getMonth();
  const totalMonths = years * 12 + months;

  return totalMonths;
};

const getAgeGroup = async (ageInMonths, ageGroups) => {
  console.log('ageInMonths', ageInMonths);
  for (const group of ageGroups) {
    const minMonths = parseFloat(group.min);
    const maxMonths = parseFloat(group.max);

    if (ageInMonths >= minMonths && ageInMonths <= maxMonths) {
      return group;
    }
  }

  return null; // Return null if no match found
};

export { handleCalculateAge, getAgeGroup };
