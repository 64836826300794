import React from 'react';
import {
  Avatar,
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  Navbar,
  NavbarBrand,
  NavbarCollapse,
  NavbarLink,
  NavbarToggle,
} from 'flowbite-react';
import Logo from '../../assets/logo.png';
import Logout from '../../assets/icons/logout.png';
import { removeUser } from '../../store/userSlice';
import { useDispatch } from 'react-redux';
function DashboardHeader() {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(removeUser());
  };

  return (
    <Navbar fluid>
      <NavbarBrand href="/dashboard">
        <img
          src={Logo}
          className="mr-3 h-10 sm:h-9"
          alt="Flowbite React Logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white"></span>
      </NavbarBrand>

      <img onClick={handleLogout} src={Logout} alt="Logout" />
    </Navbar>
  );
}

export default DashboardHeader;
