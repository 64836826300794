import api from './api';

const addNewchild = async (answers) => {
  try {
    const response = await api.post('/child/addnew', {
      answers,
    });
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

//get all children for a parent
const getChildren = async (parentId) => {
  console.log('getChildren', parentId);
  try {
    const response = await api.get(`/child/getChildren/${parentId}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

const getSingleChild = async (childId) => {
  try {
    const response = await api.get(`/child/getChild/${childId}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export { addNewchild, getChildren, getSingleChild };
