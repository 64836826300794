import { Fragment, useState } from 'react';

export default function SelectInput({
  label,
  options,
  placeholder,
  width,
  height,
  name,
  onChange,
  error,
}) {
  const [selected, setSelected] = useState();

  return (
    <form dir="rtl" className="max-w-sm mx-auto w-full mb-2">
      <label
        for="countries_disabled"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </label>
      <select
        onChange={(e) => onChange(name, e.target.value)}
        id="countries_disabled"
        name={name}
        className="bg-gray-50 border mt-3 border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option selected>{placeholder}</option>
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </form>
  );
}
