import { Button } from 'flowbite-react';
import React from 'react';
import AuthButton from '../../components/AuthButton';
import api from '../../api/api';
import isValidIranianPhoneNumber from '../../api/phoneValidator';
import { convertToEnglishNumber } from '../../api/convertNumbers';

function PhoneInput({
  setPhoneNumber,
  phoneNumber,
  setStatus,
  setErrorMessage,
}) {
  const handleLogin = async () => {
    console.log('phoneNumber', convertToEnglishNumber(phoneNumber));
    const fixedNumber = convertToEnglishNumber(phoneNumber);
    setStatus('pending');
    const res = await isValidIranianPhoneNumber(fixedNumber);
    if (!res) {
      setStatus('error');
      setErrorMessage('شماره موبایل وارد شده صحیح نمی باشد');
      return;
    }
    const result = await api.post('/api/login/', {
      phoneNumber: fixedNumber,
    });
    setStatus('pending');
    if (result.status === 200) {
      setErrorMessage(result.data.data.verificationCode);
      setStatus('success');
      console.log('result', result);
    } else {
      setStatus('error');
    }
  };

  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">ورود یا ثبت نام</h1>
      <p
        className="text-gray-600 mb-4 text-center w-1/2 text-xs
     ">
        با یک حساب کاربری از تمام خدمات منکا استفاده کنید
      </p>

      <div className="mb-4 w-1/2">
        <label for="username" className="block text-gray-600">
          شماره موبایل
        </label>
        <br />
        <input
          onChange={(e) =>
            setPhoneNumber(convertToEnglishNumber(e.target.value))
          }
          dir="rtl"
          type="phone"
          id="phone_number"
          name="username"
          className="w-full text-right  border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
          autocomplete="off"
        />

        <button
          onClick={handleLogin}
          style={{
            background: '#3F5F90',
          }}
          className="w-full py-2 text-white rounded-md mt-4 bg-primary">
          ادامه
        </button>
      </div>
    </>
  );
}

export default PhoneInput;
