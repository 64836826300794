import api from './api';

const getSingleAsqTest = async (parentId) => {
  console.log('parentId getSingleAsqTest', parentId);
  try {
    const response = await api.get(`/answer/asqanswersByParent/${parentId}`);
    // const data = await response.json();

    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export { getSingleAsqTest };
