import React from 'react';

function TextInput({
  placeholder,
  width,
  name,
  onChange,
  label,
  disabled,
  value,
  error,
}) {
  console.log('TextInput', error);
  return (
    <div className="w-full flex flex-col mb-2">
      <label className="block mb-3 text-sm font-medium text-gray-700">
        {label}
      </label>
      <input
        value={value}
        disabled={disabled}
        name={name}
        required
        onChange={(e) => onChange(name, e.target.value)}
        className="block w-full rounded-md border-1  border-gray-300 p-2 ml-3 mb-2 mt-3
      "
        type="text"
        placeholder={label}
      />
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
}

export default TextInput;
