const isValidIranianPhoneNumber = async (phoneNumber, exact = true) => {
  const REGEX = /(\+98|0)?9\d{9}/;
  const EXACT_REGEX = /^(\+98|0)?9\d{9}$/;
  if (exact) {
    // Use EXACT_REGEX for exact matching
    return EXACT_REGEX.test(phoneNumber);
  } else {
    // Use REGEX for general matching
    return REGEX.test(phoneNumber);
  }
};

export default isValidIranianPhoneNumber;
