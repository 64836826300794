import { Button } from 'flowbite-react';
import React, { useState } from 'react';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import PersianDatePicker from '../../../components/DatePicker';
import RadionbuttonComponent from '../../../components/RadionbuttonComponent';
import TextArea from '../../../components/TextArea';
import { addNewchild } from '../../../api/ChildStoreServer';
import { useSelector } from 'react-redux';
import Alertmodal from '../../../components/Alertmodal';

function ChildInfo() {
  const [answers, setAnswers] = useState({});
  const parentId = useSelector((state) => state.user.userId);
  const [status, setStatus] = useState('idle');
  const [inputErrors, setInputErrors] = useState({});

  // const handleSave = async (event) => {
  //   setStatus('pending');
  //   console.log('answers before add new child', answers);
  //   event.preventDefault();
  //   const answersWithParentId = { ...answers, parentId: parentId };
  //   const result = await addNewchild(answersWithParentId);
  //   if (result.data.status === 200) {
  //     setStatus('success');
  //   } else setStatus('error');
  //   console.log(answersWithParentId);
  // };

  const handleInputChange = (name, value) => {
    setAnswers((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const requiredFields = [
      'firstName',
      'lastName',
      'gender',
      'birthDate',
      'birthType',
      'isPregnancyWanted',
      'childOrder',
      'distqanceBetweenFirstandSecond',
      'distanceBetweenSecondAndThird',
      'birthProblem',
      'effectOnChild',
      'childHospitalized',
      'childTakingMedication',
      'motherBirthDate',
      'motherEducation',
      'motherOccupation',
      'fatherBirthDate',
      'fatherEducation',
      'fatherOccupation',
      'motherHasMentalDisorder',
      'fatherHasMentalDisorder',
      'hasSeriousMaritalConflict',
      'isLivingWithAnotherPerson',
    ];

    const newInputErrors = {};

    requiredFields.forEach((field) => {
      if (!answers[field]) {
        newInputErrors[field] = 'این فیلد اجباری است';
      }
    });

    if (Object.keys(newInputErrors).length > 0) {
      setInputErrors(newInputErrors);
      setStatus('error');
      return;
    }

    setStatus('pending');
    const answersWithParentId = { ...answers, parentId: parentId };
    const result = await addNewchild(answersWithParentId);
    if (result.data.status === 200) {
      setStatus('success');
    } else {
      setStatus('error');
    }
  };

  return (
    <div
      style={{
        padding: '10px',
        margin: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
      className="w-full mr-3 ml-3 mb-3">
      <div
        className="mt-10 mb-10 grid grid-cols-4 gap-2
      ">
        <TextInput
          name="firstName"
          onChange={handleInputChange}
          label="نام"
          placeholder=""
          error={inputErrors.firstName}
        />
        <TextInput
          onChange={handleInputChange}
          name="lastName"
          label="نام خانوادگی"
          placeholder=" "
          error={inputErrors.lastName}
        />
        <SelectInput
          onChange={handleInputChange}
          label="جنسیت"
          name="gender"
          error={inputErrors.gender}
          options={[
            {
              value: 'boy',
              label: 'پسر',
            },
            {
              value: 'girl',
              label: 'دختر',
            },
          ]}
        />
      </div>
      <div
        className="mt-10 mb-10 grid grid-cols-4 gap-2
      ">
        <PersianDatePicker
          error={inputErrors.birthDate}
          name="birthDate"
          onChange={handleInputChange}
          label="تاریخ تولد"
        />
        <SelectInput
          onChange={handleInputChange}
          label="نوع زایمان"
          error={inputErrors.birthType}
          name="birthType"
          options={[
            {
              value: 'natural',
              label: 'طبیعی',
            },
            {
              value: 'cesarean',
              label: 'سزارین',
            },
          ]}
        />
        <SelectInput
          onChange={handleInputChange}
          name="isPregnancyWanted"
          error={inputErrors.isPregnancyWanted}
          label=" آیا بارداری شما خواسته بوده است؟"
          options={[
            {
              value: true,
              label: 'بله',
            },
            {
              value: false,
              label: 'خیر',
            },
          ]}
        />
      </div>
      <div
        className="mt-10 grid grid-cols-4 gap-2
      ">
        <SelectInput
          name="childOrder"
          onChange={handleInputChange}
          error={inputErrors.childOrder}
          label="فرزند چندم می باشد؟"
          options={[
            {
              value: 'first',
              label: 'اول',
            },
            {
              value: 'second',
              label: 'دوم',
            },
            {
              value: 'third',
              label: 'سوم',
            },
            {
              value: 'fourth',
              label: 'چهارم',
            },
            {
              value: 'fifth',
              label: 'پنجم',
            },
            {
              value: 'sixth',
              label: 'ششم',
            },
            {
              value: 'seventh',
              label: 'هفتم',
            },
            {
              value: 'eighth',
              label: 'هشتم',
            },
            {
              value: 'ninth',
              label: 'نهم',
            },
            {
              value: 'tenth',
              label: 'دهم',
            },
          ]}
        />
        <SelectInput
          name="distqanceBetweenFirstandSecond"
          error={inputErrors.distqanceBetweenFirstandSecond}
          onChange={handleInputChange}
          label="فاصله سنی فرزند اول و دوم"
          options={[
            {
              value: 1,
              label: 'یک سال',
            },
            {
              value: 2,
              label: 'دو سال',
            },
            {
              value: 3,
              label: 'سه سال',
            },
            {
              value: 4,
              label: 'چهار سال',
            },
            {
              value: 5,
              label: 'پنج سال',
            },
            {
              value: 6,
              label: 'شش سال',
            },
            {
              value: 7,
              label: 'هفت سال',
            },
            {
              value: 8,
              label: 'هشت سال',
            },
            {
              value: 9,
              label: 'نه سال',
            },
            {
              value: 10,
              label: 'ده سال',
            },
          ]}
        />
        <SelectInput
          name="distanceBetweenSecondAndThird"
          onChange={handleInputChange}
          error={inputErrors.distanceBetweenSecondAndThird}
          label="فاصله سنی فرزند دوم و سوم"
          options={[
            {
              value: 1,
              label: 'یک سال',
            },
            {
              value: 2,
              label: 'دو سال',
            },
            {
              value: 3,
              label: 'سه سال',
            },
            {
              value: 4,
              label: 'چهار سال',
            },
            {
              value: 5,
              label: 'پنج سال',
            },
            {
              value: 6,
              label: 'شش سال',
            },
            {
              value: 7,
              label: 'هفت سال',
            },
            {
              value: 8,
              label: 'هشت سال',
            },
            {
              value: 9,
              label: 'نه سال',
            },
            {
              value: 10,
              label: 'ده سال',
            },
          ]}
        />
      </div>
      <div
        className="mt-10 mb-10 grid grid-col gap-2
      ">
        <RadionbuttonComponent
          name="birthProblem"
          error={inputErrors.birthProblem}
          onChange={handleInputChange}
          options={[
            {
              value: 'noProblem',
              label: 'بدون مشکل',
            },
            {
              value: 'prematureBirth',
              label: 'تولد زودهنگام',
            },
            {
              value: 'lowWeight',
              label: 'نارسی',
            },
            {
              value: 'jaundice',
              label: 'آسیب به سر ',
            },
            {
              value: 'respiratoryProblems',
              label: ' کمبود اکسیژن',
            },
            {
              value: 'other',
              label: 'سایر مشکلات',
            },
          ]}
          label="مشکلات هنگام تولد"
        />
        <TextArea name="birthProblemDescription" title="توضیحات" />
      </div>

      <div
        className="mt-10 mb-10 grid grid-col gap-2
      ">
        <RadionbuttonComponent
          error={inputErrors.effectOnChild}
          name="effectOnChild"
          onChange={handleInputChange}
          options={[
            {
              value: true,
              label: 'بله',
            },
            {
              value: false,
              label: 'خیر',
            },
          ]}
          label="آیا بیماری اثرات جسمانی / ذهنی بعدی بر کودک داشته است ( براساس تشخیص متخصص ) ؟"
        />
        <TextArea name="effectOnChildDescription" title="توضیحات" />
      </div>
      <div
        className="mt-10 mb-10 grid grid-col gap-2
      ">
        <RadionbuttonComponent
          name="childHospitalized"
          error={inputErrors.childHospitalized}
          onChange={handleInputChange}
          options={[
            {
              value: true,
              label: 'بله',
            },
            {
              value: false,
              label: 'خیر',
            },
          ]}
          label="آیا کودک پس از تولد به دلیل بیماری بستری و یا تحت نظر پزشک بوده است؟"
        />
        <TextArea name="childHospitalizedDescription" title="توضیحات" />
      </div>
      <div
        className="mt-10 mb-10 grid grid-col gap-2
      ">
        <RadionbuttonComponent
          error={inputErrors.childTakingMedication}
          name="childTakingMedication"
          onChange={handleInputChange}
          options={[
            {
              value: true,
              label: 'بله',
            },
            {
              value: false,
              label: 'خیر',
            },
          ]}
          label="آیا کودک دارو مصرف می‌کند؟"
        />
        <TextArea name="childTakingMedicationDescription" title="توضیحات" />
      </div>
      <div
        className="mt-10 mb-10 grid grid-cols-4 gap-2
      ">
        <PersianDatePicker
          error={inputErrors.motherBirthDate}
          onChange={handleInputChange}
          name="motherBirthDate"
          label="تاریخ تولد مادر"
        />
        <SelectInput
          error={inputErrors.motherEducation}
          name="motherEducation"
          onChange={handleInputChange}
          label="تحصیلات مادر"
          options={[
            {
              value: 'noEducation',
              label: 'بی سواد',
            },
            {
              value: 'elementary',
              label: 'ابتدایی',
            },
            {
              value: 'guidance',
              label: 'راهنمایی',
            },
            {
              value: 'highSchool',
              label: 'دبیرستان',
            },
            {
              value: 'associate',
              label: 'کاردانی',
            },
            {
              value: 'bachelor',
              label: 'کارشناسی',
            },
            {
              value: 'master',
              label: 'کارشناسی ارشد',
            },
            {
              value: 'phd',
              label: 'دکترا',
            },
          ]}
        />{' '}
        <SelectInput
          error={inputErrors.motherOccupation}
          name="motherOccupation"
          onChange={handleInputChange}
          label="شغل مادر"
          options={[
            {
              value: 'housewife',
              label: 'خانه دار',
            },
            {
              value: 'employee',
              label: 'کارمند',
            },
            {
              value: 'entrepreneur',
              label: 'کارآفرین',
            },
            {
              value: 'student',
              label: 'دانشجو',
            },
            {
              value: 'unemployed',
              label: 'بیکار',
            },
            {
              value: 'other',
              label: 'سایر',
            },
          ]}
        />
      </div>
      <div
        className="mt-10 mb-10 grid grid-cols-4 gap-2
      ">
        <PersianDatePicker
          error={inputErrors.fatherBirthDate}
          name="fatherBirthDate"
          onChange={handleInputChange}
          label="تاریخ تولد پدر"
        />
        <SelectInput
          error={inputErrors.fatherEducation}
          name="fatherEducation"
          onChange={handleInputChange}
          label="تحصیلات پدر"
          options={[
            {
              value: 'noEducation',
              label: 'بی سواد',
            },
            {
              value: 'elementary',
              label: 'ابتدایی',
            },
            {
              value: 'guidance',
              label: 'راهنمایی',
            },
            {
              value: 'highSchool',
              label: 'دبیرستان',
            },
            {
              value: 'associate',
              label: 'کاردانی',
            },
            {
              value: 'bachelor',
              label: 'کارشناسی',
            },
            {
              value: 'master',
              label: 'کارشناسی ارشد',
            },
            {
              value: 'phd',
              label: 'دکترا',
            },
          ]}
        />{' '}
        <SelectInput
          error={inputErrors.fatherOccupation}
          name="fatherOccupation"
          onChange={handleInputChange}
          label="شغل پدر"
          options={[
            {
              value: 'employee',
              label: 'کارمند',
            },
            {
              value: 'entrepreneur',
              label: 'کارآفرین',
            },
            {
              value: 'student',
              label: 'دانشجو',
            },
            {
              value: 'unemployed',
              label: 'بیکار',
            },
            {
              value: 'other',
              label: 'سایر',
            },
          ]}
        />
      </div>
      <div
        className="mt-10 mb-10 grid grid-col gap-2
      ">
        <RadionbuttonComponent
          error={inputErrors.motherHasMentalDisorder}
          name="motherHasMentalDisorder"
          onChange={handleInputChange}
          options={[
            {
              value: true,
              label: 'بله',
            },
            {
              value: false,
              label: 'خیر',
            },
          ]}
          label="آیا مادر دچار اختلال روان شناختی یا روان پزشکی بوده است که به خاطر آن تحت درمان قرار گرفته باشد؟"
        />
        <TextArea name="motherHasMentalDisorderDescription" title="توضیحات" />
      </div>
      <div
        className="mt-10 mb-10 grid grid-col gap-2
      ">
        <RadionbuttonComponent
          error={inputErrors.fatherHasMentalDisorder}
          name="fatherHasMentalDisorder"
          onChange={handleInputChange}
          options={[
            {
              value: true,
              label: 'بله',
            },
            {
              value: false,
              label: 'خیر',
            },
          ]}
          label="آیا پدر دچار اختلال روان شناختی یا روان پزشکی بوده است که به خاطر آن تحت درمان قرار گرفته باشد؟"
        />
        <TextArea name="fatherHasMentalDisorderDescription" title="توضیحات" />
      </div>
      <div
        className="mt-10 mb-10 grid grid-col gap-2
      ">
        <RadionbuttonComponent
          error={inputErrors.hasSeriousMaritalConflict}
          name="hasSeriousMaritalConflict"
          onChange={handleInputChange}
          options={[
            {
              value: true,
              label: 'بله',
            },
            {
              value: false,
              label: 'خیر',
            },
          ]}
          label="آیا میان والدین اختلاف نظر جدی و تعارض زناشویی وجود دارد؟"
        />
        <TextArea name="hasSeriousMaritalConflictDescription" title="توضیحات" />
      </div>
      <div
        className="mt-10 mb-10 grid grid-col gap-2
      ">
        <RadionbuttonComponent
          error={inputErrors.isLivingWithAnotherPerson}
          name="isLivingWithAnotherPerson"
          onChange={handleInputChange}
          options={[
            {
              value: true,
              label: 'بله',
            },
            {
              value: false,
              label: 'خیر',
            },
          ]}
          label="آیا شخص دیگری علاوه بر خانواده اصلی ( پدر، مادر، کودکان ) همراه شما زندگی میکند؟"
        />
        <TextArea name="livingWithAnotherPersonDescription" title="توضیحات" />
      </div>
      <hr />
      <div className="mt-6 w-48 flex items-center justify-end gap-4">
        <Button
          style={{
            backgroundColor: '#3F5F90',
            color: 'white',
            //padding: '14px 20px',
            ///margin: '8px 0',
            border: 'none',
            cursor: 'pointer',
            height: '50px',
            width: '10%',
            opacity: '0.9',
          }}
          onClick={(e) => handleSave(e)}
          className="bg-slate-800 text-buttonColor font-bold py-2 px-4"
          size="md">
          ذخیره
        </Button>

        <Button
          style={{
            backgroundColor: '#ffffff',
            color: 'black',
            //padding: '14px 20px',
            ///margin: '8px 0',
            border: '1px solid #3F5F90',
            cursor: 'pointer',
            height: '50px',
            width: '10%',
            opacity: '0.9',
          }}
          className="bg-red-500 hover:bg-red-600 py-2 px-4"
          size="md"
          type="button">
          انصراف
        </Button>
      </div>
      {status === 'error' && (
        <Alertmodal
          children={'خطا در ثبت اطلاعات'}
          buttons={[{ title: 'تلاش مجدد', onClick: () => setStatus('idle') }]}
        />
      )}
      {status === 'success' && (
        <Alertmodal
          children="اطلاعات کودک با موفقیت ثبت شد."
          buttons={[
            {
              title: 'بازگشت به صفحه اصلی',
              onClick: () => (window.location.href = '/dashboard/children'),
            },
          ]}
        />
      )}
    </div>
  );
}

export default ChildInfo;
