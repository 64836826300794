import React, { useState } from 'react';
import { DashboardSidebar } from '../sidebar';
import DashboardHeader from '../header';
import ChildrenSection from './ChildrenSection';
import { DashboardInbox } from './DashboarInbox';
import LastResults from './LastResults';
import ProfileIndex from '../profile/profileIndex';
import ChildInfo from '../children/ChildInfo';

function Dashboardindex() {
  const [selectedSection, setSelectedSection] = useState('main');
  console.log('main');
  return (
    <div className="w-full flex flex-wrap">
      <ChildrenSection location={'main'} />
      <DashboardInbox />
      <LastResults />
    </div>
  );
}

export default Dashboardindex;
