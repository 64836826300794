import React, { useEffect, useState } from 'react';
import Radionbuttons from '../../../components/RadioButtonMenka';
import { Button } from 'flowbite-react';
import Arrow from '../../../assets/arrow.svg';
import asqs from '../../../data/asqs';
import { handleSendAsqAnswers } from '../../../api/handleSqlAnswers';
import { useLocation } from 'react-router-dom';

const answers = [
  { label: 'بله', value: 10 },
  { label: 'هنوز نه', value: 0 },
  { label: 'گاهی', value: 5 },
];

function MainQuestionIndex() {
  //get navigate state from react router dom
  const { childId, parentId, childFirstName, childLastName } =
    useLocation().state;
  console.log('childId:', childId, 'parentId:', parentId);
  const [currentStep, setCurrentStep] = useState(0);
  const [ageGroup, setAgeGroup] = useState(''); // <-- Add this line
  const [roomType, setRoomType] = useState(''); // <-- Add this line
  const [completedQuestions, setCompletedQuestions] = useState([]);
  // i need child and parent id here
  useEffect(() => {
    const ageGroup = JSON.parse(localStorage.getItem('ageGroup'));
    const roomType = JSON.parse(localStorage.getItem('roomType'));
    setRoomType(roomType);
    setAgeGroup(ageGroup);
  }, []);

  const filteredQuestions = asqs.filter(
    (question) =>
      question.ageGroup === ageGroup.title && question.roomType === roomType
  );

  const lengthOfQuestions = filteredQuestions.length;
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(filteredQuestions?.length).fill('')
  );

  useEffect(() => {
    if (currentStep === filteredQuestions.length) {
      console.log('All questions answered');
      // All questions answered, save to localStorage
      localStorage.setItem('asqAnswers', JSON.stringify(completedQuestions));
    }
  }, [currentStep, completedQuestions, filteredQuestions.length]);

  const handleSaveAnswers = async () => {
    try {
      const res = await handleSendAsqAnswers(
        childId,
        parentId,
        childFirstName,
        childLastName,
        completedQuestions,
        roomType,
        ageGroup
      );
      window.location.replace(`/dashboard/asqtest/ConfirmPage`);
    } catch (error) {
      console.log('error:', error);
    }
  };

  console.log(
    'currentStep',
    currentStep,
    'and',
    'lengthOfQuestions',
    lengthOfQuestions
  );

  const renderQuestion = () => {
    if (currentStep < lengthOfQuestions) {
      const question = filteredQuestions[currentStep]; // Access current question
      return (
        <div className="flex flex-col w-full h-full items-center justify-center">
          <div className="text-lg font-bold text-gray-900 dark:text-white mb-3">
            {!question.questions.image && (
              <div className="flex flex-row items-center justify-center mb-5">
                <svg
                  class="w-80 h-80 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18">
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            )}
            {/* { <img src={Arrow} alt="Arrow" className="inline-block w-8 h-8" />} */}
            <div
              dangerouslySetInnerHTML={{ __html: question.questions.question }}
            />
          </div>
          <div className=" gap-4 flex flex-row text-sm font-normal text-gray-900 dark:text-white mb-3">
            {answers.map((item, index) => (
              <Radionbuttons
                key={index}
                style={''}
                value={item.value} // Set value dynamically
                checked={selectedAnswers[currentStep] === item}
                onChange={() => handleAnswerChange(currentStep, item)}
                label={item.label}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  const handleNextStep = () => {
    if (currentStep < filteredQuestions.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      // Last question, save answer and navigate
      const updatedCompletedQuestions = [
        ...completedQuestions,
        {
          question: filteredQuestions[currentStep].questions.question,
          answer: selectedAnswers[currentStep],
        },
      ];
      setCompletedQuestions(updatedCompletedQuestions);
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    {
      // currentStep === filteredQuestions.length &&
      // window.location.replace(`/dashboard/asqtest/3/ConfirmPage`);
    }
  }, [currentStep]);

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleAnswerChange = (index, answer) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[index] = answer;
    setSelectedAnswers(updatedAnswers);
    const updatedCompletedQuestions = completedQuestions.slice();
    updatedCompletedQuestions[index] = {
      question: filteredQuestions[index].questions.question,
      answer: answer,
    };
    setCompletedQuestions(updatedCompletedQuestions);
  };

  return (
    <div className="flex flex-col w-full h-screen bg-gray-100 p-5">
      <div
        onClick={() => {
          window.location.href = '/dashboard/questionnaire';
        }}
        className=" mr-3 text-sm cursor-pointer flex flex-row">
        <img src={Arrow} alt="Arrow" className="inline-block w-8 h-8" />
        <h1 className="text-md font-bold text-gray-900 dark:text-white mb-5 mt-2 ml-5">
          بازگشت به صفحه اصلی
        </h1>
      </div>
      <hr />
      {currentStep < filteredQuestions.length && (
        <>
          <div className="flex flex-row w-full justify-start items-center mt-8">
            {currentStep + 1}/{lengthOfQuestions}
          </div>

          {renderQuestion()}
          <div className="flex flex-row w-1/4 justify-center gap-4 mt-20">
            <Button color="light" onClick={handlePrevStep}>
              قبلی
            </Button>
            <Button
              disabled={selectedAnswers[currentStep] === ''}
              onClick={handleNextStep}>
              بعدی
            </Button>
          </div>
        </>
      )}
      {currentStep === filteredQuestions.length && (
        <>
          <div className="flex flex-col w-full h-full items-center justify-center">
            <div className="text-lg font-bold text-gray-900 dark:text-white mb-5">
              همه سوالات پاسخ داده شده است
            </div>
            <div className=" gap-4 mt-5 flex flex-row text-sm font-normal text-gray-900 dark:text-white mb-3">
              <Button className="mt-5" onClick={handleSaveAnswers}>
                ذخیره و خروج{' '}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MainQuestionIndex;
