import React from 'react';
import ArrowLeft from '../assets/arrow-left.svg';

function ItemInboxHome({ title, date }) {
  return (
    <li
      className="py-3  border border-gray-200  rounded px-4 mx-2 my-2 mt-3
    ">
      <div className="flex items-center ">
        <div className="min-w-0 flex-1">
          <p className="truncate text-md font-bold text-gray-900 dark:text-white">
            {title}
          </p>
        </div>

        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
          {date}
        </div>
        <img src={ArrowLeft} alt="Arrow" className="inline-block" />
      </div>
    </li>
  );
}

export default ItemInboxHome;
