import { Sidebar } from 'flowbite-react';
import Home from '../../assets/icons/Home.svg';
import Avatar from '../../assets/avatar.png';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

export function DashboardSidebar({ setSelectedSection }) {
  const handleSelection = (section) => {
    setSelectedSection(section);
  };

  const name = useSelector((state) => state.user.name);

  const menuItems = [
    {
      name: 'پیشخوان',
      icon: Home,
      path: '/dashboard',
    },
    {
      name: 'حساب کاربری',
      icon: Home,
      path: '/dashboard/profile',
    },
    {
      name: 'فرزندان من',
      icon: Home,
      path: '/dashboard/childinfo',
    },
    {
      name: 'پرسش نامه',
      icon: Home,
      path: '/dashboard/questionnaire',
    },
    {
      name: 'پرداخت های من',
      icon: Home,
      path: '/dashboard/payment',
    },
    {
      name: 'کارگاه های من',
      icon: Home,
      path: '/dashboard/workshops',
    },
    {
      name: 'پیغام ها',
      icon: Home,
      path: '/dashboard/messages',
    },
  ];

  return (
    <Sidebar className="h-screen" aria-label="Sidebar with logo ">
      <Sidebar.Logo href="#">
        <img src={Avatar} alt="user" className="w-8 h-8 inline-block ml-2" />
        {name ? name : 'کاربر'}
      </Sidebar.Logo>
      <ul>
        {menuItems.map((item, index) => (
          <li key={index}>
            <NavLink
              className="text-grey-400 hover:text-grey-600  inline-block ml-5 mr-5 mt-3   rounded-lg p-2 w-full text-right"
              // activeClassName="bg-primary text-white"
              key={index}
              icon={item.icon}
              onClick={() => handleSelection(item.path)}
              aria-current="page"
              to={item.path}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </Sidebar>
  );
}
