const parentingTest = [
  {
    id: 1,
    question:
      'والدین باید به بچه های خود اجازه دهند تا هر آن چه را که می خواهند، انجام دهند.',
  },
  {
    id: 2,
    question:
      'بچه ها باید فقط از آن چه والدینشان می گویند، پیروی کنند، در غیر اینصورت بادی آنها را تنبیه کرد.',
  },
  {
    id: 3,
    question:
      'اگر به مشکلات بچه ها توجه شود، این دردسر را به دنبال داردکه آنها گستاخ تر شده و انتظارات بیشتری از والدین خواهند داشت.',
  },
  {
    id: 4,
    question:
      'هرگاه والدین تصمیمی برای بچه ها بگیرند، باید دلایل آن را به آن ها بگویند.',
  },
  {
    id: 5,
    question:
      'اگر بچه ها نسبت به محدودیت های ایجاد شده در خانواده اعتراضی داشتند، والدین باید با صحبت کردن، آن ها را قانع کنند.',
  },
  {
    id: 6,
    question:
      'والدین نباید در کارهای مربوط به بچه ها دخالت کنند، چون بچه ها به طور ذاتی راه خودشان را پیدا می کنند.',
  },
  {
    id: 7,
    question:
      'اگر بچه ها کاملا مطابق میل والدین خود رفتار کنند، در آینده افراد موفقی خواهند شد.',
  },
  {
    id: 8,
    question:
      'بچه ها باید کاری را که والدین از آن ها انتظار دارند، انجام دهند. اما اگر انتظارات والدین غیر قابل قبول بود، باید بتوانند آن را آزادانه با والدین خود مطرح کنند.',
  },
  {
    id: 9,
    question:
      'وقتی والدین از بچه ها می خواهند تا کاری را انجام دهند، بچه ها باید بدون چون و چرا آن را انجام دهند.',
  },
  {
    id: 10,
    question:
      'والدین نباید بچه ها را در منزل محدود کنند، بلکه باید آن ها را آزاد بگذارند تا تمام لوازم اطراف خود را دستکاری کنند.',
  },
  {
    id: 11,
    question:
      'هرگاه بچه ها بر خلاف میل والدین رفتار کردند، والدین باید به جای تنبیه آنها را راهنمایی کنند.',
  },
  {
    id: 12,
    question: 'والدین خیلی زود باید به بچه ها بفهمانند که رئیس خانواده کیست.',
  },
  {
    id: 13,
    question:
      'والدین باید در تصمیم گیری های مربوط ب خانواده فقط آن چه را که خواست بچه ها است، انجام دهند.',
  },
  {
    id: 14,
    question:
      'اگر والدین رفتار و خواست های بچه ها را محدود نکنند، بیشتر مشکلات حل می شود.',
  },
  {
    id: 15,
    question:
      'والدین باید هنگام تصمیم گیری در مورد مسائل و برنامه های خانواده نظر بچه ها را دخالت دهند و از آنها نیز نظر خواهی کنند.',
  },
  {
    id: 16,
    question:
      'والدین باید با بچه هایی که با نظر آنها مخالفت می کنند، به شدت برخورد نمایند.',
  },
  {
    id: 17,
    question:
      'والدین باید به بچه ها اجازه دهند تا خودشان در کارها تصمیم گیرنده باشند.',
  },
  {
    id: 18,
    question: 'شوخی و بازی کردن بچه ها در حضور والدین، توهین به آنهاشت.',
  },
  {
    id: 19,
    question:
      'بچه ها به بازی و تفریح نیاز دارند، برای این کار والدین باید به آن ها اجازه دهند تا هروقت که دلشان خواست از منزل خارج شوند.',
  },
  {
    id: 20,
    question:
      'باید به بچه ها اجازه داده شود که اگر فکر می کنند نظرشان از نظرات والدین خود بهتر است، آن را ابراز کنند.',
  },
  {
    id: 21,
    question:
      'وقتی بچه ها مرتکب اشتباهی می شوند، هیچ گاه نباید آن ها را سرزنش کرد.',
  },
  {
    id: 22,
    question:
      'اگر والدین تصمیمی برخلاف میل بچه ها گرفتند، باید با بچه ها گفتگو کنند و حتی اگر در تصمیم خود اشتباه کرده بودند، آن را بپذیرند.',
  },
  {
    id: 23,
    question:
      'گاهی اوقات والدین باید با بچه ها سازش کنند، نه این که همیشه انتظار داشته باشند که بچه ها با آنها سازش نمایند.',
  },
  {
    id: 24,
    question: 'بچه ها را باید آزاد گذاشت تا خودشان تجربه کنند.',
  },
  {
    id: 25,
    question:
      'بعضی از بچه ها ذاتا بد هستند و باید طوری تربیت شوند که از والدین خود بترسند.',
  },
  {
    id: 26,
    question:
      'بچه ها وقتی بزرگ شوند، از سخت گیری والدین خود سپاسگزاری خواهند کرد.',
  },
  {
    id: 27,
    question:
      'وقتی بچه ها دچار مشکل و دردسر می شوند (کار خطایی انجام می دهند) باید بدانند که با مطرح کردن آن مشکل برای والدین خود، تنبیه نمی شوند.',
  },
  {
    id: 28,
    question:
      'در مجالس و میهمانی ها باید بچه ها را آزاد گذاشت تا محیط اطراف خود را دستکاری کنند تا حس کنجکاوی آن ها ارضا شود.',
  },
  {
    id: 29,
    question:
      'بچه ها جدی و کوشا نخواهند بود، مگر این که درباره کارهای آنها سخت گیری کنیم.',
  },
  {
    id: 30,
    question:
      'هر چند بچه ها تجربه کمی دارند، اما گاهی اوقات نظر آن ها بهتر از نظر والدین است.',
  },
];

export default parentingTest;
