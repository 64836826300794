import React from 'react';
import ageGroups from '../../../data/ageGroups';

function AgeGroups({ handleStoreValueOfAgeGroup, selectedAgeGroup }) {
  console.log(selectedAgeGroup);
  return (
    <div>
      <div className="flex flex-row my-2">
        براساس سن فرزندتان، پرسش‌نامه مورد نظرتان را انتخاب کنید :
      </div>
      <br />
      <div className="grid grid-cols-4 gap-2 my-2 mb-2">
        {ageGroups.map((ageGroup, i) => {
          return (
            <div
              key={i}
              onClick={() =>
                selectedAgeGroup === ageGroup
                  ? handleStoreValueOfAgeGroup(ageGroup.title)
                  : null
              }
              style={{
                cursor:
                  selectedAgeGroup === ageGroup ? 'pointer' : 'not-allowed',
                border:
                  selectedAgeGroup === ageGroup
                    ? '1px solid #3F5F90'
                    : '0.5px solid lightgrey',
              }}
              className="flex flex-col border border-grey-200 rounded-md items-center h-48 p-2">
              <div className="mb-3 flex w-auto h-72 rounded-md backdrop-filter">
                <img
                  style={{
                    opacity: selectedAgeGroup === ageGroup ? 1 : 0.5,
                  }}
                  src={ageGroup.img}
                  alt="Card Image"
                  className={`object-contain `}
                />
              </div>
              <div className="mb-3 flex rounded-md">{ageGroup.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AgeGroups;
