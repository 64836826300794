import api from './api';

const createNewOrder = async (productName, parentId, orderTotal) => {
  console.log('productName:', productName);
  console.log('parentId:', parentId);
  console.log('orderTotal:', orderTotal);
  try {
    const res = await api.post('/order/addnew', {
      userId: parentId,
      orderItems: productName,
      orderTotal: orderTotal,
      orderStatus: 'pending',
    });
    console.log('res:', res);
    return res;
  } catch (error) {
    console.error('Error:', error);
  }
};

export { createNewOrder };
