import { Button, Label, Modal, Radio } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RadionbuttonComponent from '../../../../components/RadionbuttonComponent';
import { storeAnswers } from '../../../../api/storeAnswers';
import Alertmodal from '../../../../components/Alertmodal';

const EditModal = ({ question, answer, onClose }) => {
  const answers = [
    'کاملا موافقم',
    'موافقم',
    'تقریبا مخالفم ',
    'مخالفم',
    'کاملا مخالفم',
  ];
  return (
    <Modal show={true} onClose={onClose}>
      <Modal.Header>{question}</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400"></p>
          <div className="flex flex-row justify-between">
            {answers?.map((item, index) => (
              <fieldset className="flex max-w-md flex-row gap-4">
                <div className="flex items-center gap-2">
                  <Radio
                    id={index}
                    name={index}
                    value={item}
                    checked={item === answer}
                  />
                  <Label htmlFor="united-state">{answer}</Label>
                </div>
              </fieldset>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={() => setOpenModal(false)}>I accept</Button>
        <Button color="gray" onClick={() => setOpenModal(false)}>
          Decline
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

function ConfirmPage() {
  const { id } = useParams();
  const [answers, setAnswers] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditIndex, setShowEditIndex] = useState(-1);
  const [childId, setChildId] = useState('');
  const [showModal, setShowModal] = useState('hide');
  const [result, setResult] = useState('');
  const [status, setStatus] = useState('idle');
  useEffect(() => {
    const childId = localStorage.getItem('selectedChild');
    setChildId(JSON.parse(childId));
  }, []);
  useEffect(() => {
    const storedAnswers =
      id === '1'
        ? JSON.parse(localStorage.getItem('parentingAnswers'))
        : JSON.parse(localStorage.getItem('attachmentAnswers'));
    setAnswers(storedAnswers || []);
  }, [id]);

  const handleEditClick = (index) => {
    setShowEditIndex(index === showEditIndex ? -1 : index);
  };

  const handleSubmit = async () => {
    console.log('answers', answers);
    console.log('childId', childId._id);
    try {
      const res = await storeAnswers(answers, childId._id);
      setShowModal('success');
    } catch (err) {
      setShowModal('error');
    }
  };

  return (
    <div
      style={{
        padding: '10px',
        margin: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
      className="flex flex-col w-full h-screen bg-gray-100 p-5 justify-between ">
      <div className="text-lg mx-4 font-bold text-gray-900 dark:text-white mb-3 flex flex-row justify-between">
        <div
          onClick={() => {
            window.location.href = '/dashboard/questionnaire';
          }}
          style={{
            cursor: 'pointer',
            justifyContent: 'left',
          }}>
          بازگشت
        </div>
        {/* <Button
          onClick={handleSubmit}
          style={{
            backgroundColor: '#3F5F90',
          }}
          className="mb-4"
          color="success">
          تایید
        </Button> */}
      </div>
      <div className="flex flex-row w-full justify-start items-center mt-8">
        <h1 className="text-lg font-bold text-gray-900 dark:text-white mb-3">
          در انتظار محاسبه نتیجه تست
        </h1>
      </div>
      <hr />

      {/* <div
        style={{
          padding: '10px',
          margin: '10px',
          backgroundColor: 'white',
          borderRadius: '10px',
        }}
        className="flex flex-col justify-start items-center mt-8 border  ">
        {answers.map((answer, index) => (
          <div
            className="flex flex-row w-full justify-start items-center mt-3"
            key={index}>
            <div
              style={{
                textAlign: 'right',
                width: '85%',
                height: '40px',
              }}
              className=" text-md font-normal text-gray-900 dark:text-white mb-3 border-b">
              {answer.question}
            </div>
            <div
              className=" text-sm font-normal text-gray-900 dark:text-white mb-3 "
              style={{ textAlign: 'left' }}>
              ({answer.answer}){' '}
              <span
                onClick={() => handleEditClick(index)}
                style={{ color: 'green', cursor: 'pointer' }}>
                {' '}
                ویرایش
              </span>
            </div>
            {showEditIndex === index && (
              <EditModal
                question={answer.question}
                answer={answer.answer}
                onClose={() => setShowEditIndex(-1)}
              />
            )}
            <hr />
          </div>
        ))}
      </div>
      {showModal === 'success' && (
        <Alertmodal
          buttons={[
            {
              title: 'باشه',
              onClick: () =>
                (window.location.href = '/dashboard/questionnaire'),
            },
          ]}>
          <h3>اطلاعات با موفقیت ثبت شد</h3>
        </Alertmodal>
      )}
      {showModal === 'error' && (
        <Alertmodal
          buttons={[
            {
              title: 'باشه',
              onClick: () =>
                (window.location.href = '/dashboard/questionnaire'),
            },
          ]}>
          <h3>خطا در ثبت اطلاعات</h3>
        </Alertmodal>
      )} */}
    </div>
  );
}

export default ConfirmPage;
