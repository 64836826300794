import { Button, Label, Modal, Radio } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RadionbuttonComponent from '../../../../components/RadionbuttonComponent';
import { storeAnswers } from '../../../../api/storeAnswers';
import PodcastAudio from '../../../../components/PodcastAudio';

const samplevideos = [
  {
    id: 1,
    title: 'ویدیوی شماره 1',
  },
  {
    id: 2,
    title: 'ویدیوی شماره 2',
  },
  {
    id: 3,
    title: 'ویدیوی شماره 3',
  },
  {
    id: 4,
    title: 'ویدیوی شماره 4',
  },
  {
    id: 5,
    title: 'ویدیوی شماره 5',
  },
  {
    id: 6,
    title: 'ویدیوی شماره 6',
  },
  {
    id: 7,
    title: 'ویدیوی شماره 7',
  },
  {
    id: 8,
    title: 'ویدیوی شماره 8',
  },
];

function AsqAfterTest() {
  const { id } = useParams();
  const [answers, setAnswers] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditIndex, setShowEditIndex] = useState(-1);
  const [childId, setChildId] = useState('');
  const [showModal, setShowModal] = useState('hide');
  const [result, setResult] = useState('');
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    const childId = localStorage.getItem('selectedChild');
    setChildId(JSON.parse(childId));
  }, []);

  useEffect(() => {
    const storedAnswers =
      id === '1'
        ? JSON.parse(localStorage.getItem('parentingAnswers'))
        : JSON.parse(localStorage.getItem('attachmentAnswers'));
    setAnswers(storedAnswers || []);
  }, [id]);

  const handleEditClick = (index) => {
    setShowEditIndex(index === showEditIndex ? -1 : index);
  };

  const handleSubmit = async () => {
    console.log('answers', answers);
    console.log('childId', childId._id);
    try {
      const res = await storeAnswers(answers, childId._id);
      setShowModal('success');
    } catch (err) {
      setShowModal('error');
    }
  };

  return (
    <>
      <div
        style={{
          padding: '10px',
          margin: '10px',

          backgroundColor: 'white',
          borderRadius: '10px',
        }}>
        <div className="text-lg mx-4 font-bold text-gray-900 dark:text-white mb-5 flex flex-row ">
          <div
            onClick={() => {
              window.location.href = '/dashboard/questionnaire';
            }}
            style={{
              cursor: 'pointer',
              justifyContent: 'left',
            }}>
            بازگشت
          </div>
          {/* <Button
          onClick={handleSubmit}
          style={{
            backgroundColor: '#3F5F90',
          }}
          className="mb-4"
          color="success"
        >
          تایید
        </Button> */}
        </div>
        <hr />

        <div className="flex flex-row items-center mt-8 h-screen-1/2 mb-5">
          <div className=" w-full bg-gray-200 p-4">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
            استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز،
            و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای
            زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و
            متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان
            رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد
            کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه
            راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
            حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود
            طراحی اساسا مورد استفاده قرار گیرد.
          </div>
          <hr />
        </div>

        <PodcastAudio />
      </div>
      <div className="flex flex-col w-full justify-start items-center mt-8  ">
        <h1 className="text-lg font-bold text-gray-900 dark:text-white mb-5">
          ویدئوهای مربوط به ASQ
        </h1>
      </div>

      <div className="grid grid-cols-4 gap-4">
        {samplevideos.map((video) => (
          <>
            <div
              key={video.id}
              className="flex justify-center items-center  bg-gray-200 rounded-md p-4
          ">
              <video controls>
                {' '}
                <div className=" position-absolute mt-5 w-1/2 h-1/2 bg-gray-900 rounded-md p-4 z-10">
                  <h3 className="text-lg font-bold text-white dark:text-white mb-3 flex flex-row">
                    {video.title}
                  </h3>
                </div>
              </video>
            </div>
          </>
        ))}
      </div>
    </>
  );
}

export default AsqAfterTest;
