import React from 'react';
import { Link } from 'react-router-dom';

function MenkaCard({ title, description, id, style, img, url, warning }) {
  return (
    <div className="mr-3 mt-3 p-6 bg-white border border-gray-200 rounded-lg w-full">
      {/* {description && (
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {description}
        </p>
      )} */}

      {img && (
        <div
          style={{
            width: '250px',
            height: '250px',
          }}
          className="mb-3 flex items-center justify-center h-72 rounded-md">
          <img src={img} alt="Card Image" className="object-contain" />
        </div>
      )}
      <Link
        to={`/dashboard/test/${id}`}
        state={{
          title: title,
          id: id,
          description: description,
          url: url,
          warning: warning,
        }}
        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
      </Link>
    </div>
  );
}

export default MenkaCard;
