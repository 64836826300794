import React, { useEffect, useState } from 'react';
import MenkaCard from '../../../components/MenkaCard';
import Parenting from '../../../assets/parenting.png';
import Attachment from '../../../assets/attachment.png';
import ASQ from '../../../assets/asq.png';

function QuestionnaireIndex() {
  const data = [
    {
      id: 1,
      title: 'فرزند پروری',
      url: `/dashboard/initialtests/${1}`,
      img: Parenting,
      description:
        'تست فرزندپروری به شما کمک می کند تا فرزندان تان را به خوبی هدایت کنید هم اکنون می توانید این تست را با تفسیری جامع انجام دهید.',
    },
    {
      id: 2,
      title: 'دلبستگی ',
      url: `/dashboard/initialtests/${2}`,
      img: Attachment,
      description:
        'اگر به اندازه کافی به کودک خود توجه داشته باشید، بلافاصله نیازهای او را برطرف کنید و با حوصله و مهربانی با او ارتباط برقرار کنید، دلبستگی ایمن را در کودک خود پرورش می‌دهد.',
    },
    {
      id: 3,
      title: ' تست ASQ',
      url: `/dashboard/test/${3}`,
      description:
        'با پرداخت هزینه تست ASQ میتوانید 2 بار از این تست استفاده کنید.',
      warning: 'لازمه انجام تست ASQ، گذراندن تست فرزندپروری و دلبستگی است.',
      img: ASQ,
    },
  ];
  return (
    <div
      style={{
        padding: '10px',
        margin: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
      className="flex flex-cols-3 gap-5 h-96">
      {data.map((item, index) => {
        return (
          <MenkaCard
            id={item.id}
            title={item.title}
            img={item.img}
            key={index}
            url={item.url}
            description={item.description}
            warning={item.warning}
          />
        );
      })}
    </div>
  );
}

export default QuestionnaireIndex;
