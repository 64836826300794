import logo from './logo.svg';
import './App.css';
import { Provider, useSelector } from 'react-redux';
import { store, persistor } from './store/store';
import UserAuth from './pages/auth/userAuth';
import Dashboardindex from './pages/dashboard/main';
import DashboardHeader from './pages/dashboard/header';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';
import ProfileIndex from './pages/dashboard/profile/profileIndex';
import ChildrenSection from './pages/dashboard/main/ChildrenSection';
import ChildInfo from './pages/dashboard/children/ChildInfo';
import { PersistGate } from 'redux-persist/integration/react';
import { DashboardSidebar } from './pages/dashboard/sidebar';
import ChildPageIndex from './pages/dashboard/children/childPage/childPageIndex';
import QuestionnaireIndex from './pages/dashboard/questionnaire/QuestionnaireIndex';
import SingleQIndex from './pages/dashboard/questionnaire/singleQuestionnarie/SingleQIndex';
import SimpleQIndex from './pages/dashboard/questionnaire/simpleQuestions/SimpleQIndex';
import ASQIndex from './pages/dashboard/ASQ/asqindex';
import MainQuestionIndex from './pages/dashboard/ASQ/MainQuestionIndex';
import PaymentHistoryIndex from './pages/dashboard/payment/PaymentHistoryIndex';
import ConfirmPage from './pages/dashboard/questionnaire/simpleQuestions/confirmPage';
import UnderConstruction from './pages/underConstruction';
import AsqAfterTest from './pages/dashboard/questionnaire/simpleQuestions/ASQAfterTest';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import React from 'react';

Bugsnag.start({
  apiKey: '25dfd043cd1576714127e3243aa7f457',
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: '25dfd043cd1576714127e3243aa7f457' });

function App() {
  const ProtectedRoute = () => {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    console.log('isLoggedIn', isLoggedIn);

    if (!isLoggedIn) {
      console.log('Redirecting to unauthorized...');
      return <Navigate to="/" />; // Redirect to login if not logged in
    }

    return <Outlet />;
  };

  const DashboardLayout = () => {
    return (
      <div>
        <DashboardHeader />

        <div className="flex flex-row bg-gray-100">
          <DashboardSidebar />
          <Outlet />
        </div>
      </div>
    );
  };
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Routes>
              <Route path="/" element={<UserAuth />} />
              <Route element={<ProtectedRoute />}>
                <Route element={<DashboardLayout />}>
                  {/* Apply layout to these routes */}
                  <Route path="/dashboard" element={<Dashboardindex />} />
                  {/* <Route path="/dashboard/profile" element={<ProfileIndex />} /> */}
                  <Route
                    path="/dashboard/profile"
                    element={<UnderConstruction />}
                  />
                  <Route
                    path="/dashboard/workshops"
                    element={<UnderConstruction />}
                  />
                  <Route
                    path="/dashboard/messages"
                    element={<UnderConstruction />}
                  />
                  <Route
                    path="/dashboard/childinfo"
                    element={<ChildrenSection />}
                  />
                  <Route path="/dashboard/addnew" element={<ChildInfo />} />
                  <Route
                    path="/dashboard/childinfo/:id"
                    element={<ChildPageIndex />}
                  />
                  <Route
                    path="/dashboard/questionnaire"
                    element={<QuestionnaireIndex />}
                  />
                  <Route
                    path="/dashboard/test/:id"
                    element={<SingleQIndex />}
                  />
                  <Route
                    path="/dashboard/children"
                    element={<ChildrenSection />}
                  />
                  <Route path="/dashboard/asq" element={<ASQIndex />} />
                  <Route
                    path="/dashboard/payment"
                    element={<PaymentHistoryIndex />}
                  />
                </Route>
                {/* No layout for this route */}
              </Route>
              <Route
                path="dashboard/initialtests/:id/start"
                element={<SimpleQIndex />}
              />

              <Route
                path="/dashboard/test/:id/start"
                element={<MainQuestionIndex />}
              />
              <Route
                path="dashboard/initialtests/:id/ConfirmPage"
                element={<ConfirmPage />}
              />
              <Route
                path="dashboard/asqtest/ConfirmPage"
                element={<AsqAfterTest />}
              />
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
