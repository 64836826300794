import React, { useEffect, useState } from 'react';
import getAsqs from '../../../api/questions';
import ageGroups from '../../../data/ageGroups';
import AgeGroups from './ageGroups';
import Rooms from './rooms';
import {
  getAgeGroup,
  handleCalculateAge,
} from '../../../api/handleAgeCalculator';

function ASQIndex() {
  const [selectedChild, setSelectedChild] = useState(null);
  console.log('selectedChild', selectedChild);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState(null);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [status, setStatus] = useState('idle');
  const [title, setTitle] = useState(' تست ASQ');
  useEffect(() => {
    getAsqs().then((res) => {});

    setSelectedChild(JSON.parse(localStorage.getItem('selectedChild')));
  }, []);

  const calculateAndDisplayAge = async () => {
    const ageInMonths = await handleCalculateAge(selectedChild?.birthDate);
    const matchedGroup = await getAgeGroup(ageInMonths, ageGroups);

    localStorage.setItem('ageGroup', JSON.stringify(matchedGroup));
    setSelectedAgeGroup(matchedGroup);
  };

  useEffect(() => {
    calculateAndDisplayAge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChild]);

  const handleStoreValueOfAgeGroup = (value) => {
    console.log(value);
    setTitle(value + ' ASQ');
    setStatus('roomSelection');
  };

  const handleStoreValueOfRoomType = (value) => {
    console.log(value);
    setStatus('question');
  };

  return (
    <div
      className="mr-3 mt-3 p-6 bg-white border border-gray-200 rounded-md flex flex-row w-full  ml-3 h-screen-1/2

    ">
      <div className="mb-3 bg-main border border-grey-200 rounded-md w-full  mr-3 ml-3 py-3 px-3">
        <div className="mb-3 flex w-full  rounded-md">
          <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
        </div>
        <hr />
        <br />
        <div
          className="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 border border-grey-200 flex flex-row dark:bg-gray-800 dark:text-gray-300"
          role="alert">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.5589 10.7401L20.2089 9.16008C19.9589 8.86008 19.7489 8.30008 19.7489 7.90008V6.20008C19.7489 5.14008 18.8789 4.27008 17.8189 4.27008H16.1189C15.7189 4.27008 15.1489 4.06008 14.8489 3.81008L13.2689 2.46008C12.5789 1.87008 11.4489 1.87008 10.7589 2.46008L9.15891 3.81008C8.85891 4.06008 8.29891 4.27008 7.89891 4.27008H6.16891C5.10891 4.27008 4.23891 5.14008 4.23891 6.20008V7.90008C4.23891 8.29008 4.03891 8.85008 3.78891 9.15008L2.43891 10.7401C1.85891 11.4401 1.85891 12.5601 2.43891 13.2401L3.78891 14.8301C4.03891 15.1201 4.23891 15.6901 4.23891 16.0801V17.7901C4.23891 18.8501 5.10891 19.7201 6.16891 19.7201H7.90891C8.29891 19.7201 8.86891 19.9301 9.16891 20.1801L10.7489 21.5301C11.4389 22.1201 12.5689 22.1201 13.2589 21.5301L14.8389 20.1801C15.1389 19.9301 15.6989 19.7201 16.0989 19.7201H17.7989C18.8589 19.7201 19.7289 18.8501 19.7289 17.7901V16.0901C19.7289 15.6901 19.9389 15.1301 20.1889 14.8301L21.5389 13.2501C22.1489 12.5701 22.1489 11.4401 21.5589 10.7401ZM11.2489 8.13008C11.2489 7.72008 11.5889 7.38008 11.9989 7.38008C12.4089 7.38008 12.7489 7.72008 12.7489 8.13008V12.9601C12.7489 13.3701 12.4089 13.7101 11.9989 13.7101C11.5889 13.7101 11.2489 13.3701 11.2489 12.9601V8.13008ZM11.9989 16.8701C11.4489 16.8701 10.9989 16.4201 10.9989 15.8701C10.9989 15.3201 11.4389 14.8701 11.9989 14.8701C12.5489 14.8701 12.9989 15.3201 12.9989 15.8701C12.9989 16.4201 12.5589 16.8701 11.9989 16.8701Z"
              fill="#C5A572"
            />
          </svg>
          <span className="font-medium mr-3 mb-2">
            شما میتوانید 2 مرتبه از تست ASQ استفاده کنید{' '}
          </span>
        </div>
        <br />
        {status === 'idle' && (
          <AgeGroups
            selectedAgeGroup={selectedAgeGroup}
            handleStoreValueOfAgeGroup={handleStoreValueOfAgeGroup}
          />
        )}
        {status === 'roomSelection' && (
          <Rooms
            childId={selectedChild?._id}
            parentId={selectedChild?.parentId}
            childFirstName={selectedChild?.firstName}
            childLastName={selectedChild?.lastName}
            selectedRoomType={selectedRoomType}
            handleStoreValueOfRoomType={handleStoreValueOfRoomType}
          />
        )}
      </div>
    </div>
  );
}

export default ASQIndex;
