import { useState } from 'react';
import ItemInboxHome from '../../../components/ItemInboxHome';

export function DashboardInbox() {
  const [status, setStatus] = useState('noMsg');
  const [messages, setMessages] = useState([
    {
      title: 'پیام جدید از منکا',
      date: '1400/09/09',
    },
    {
      title: 'پیام جدید از منکا',
      date: '1400/09/09',
    },
    {
      title: 'پیام جدید از منکا',
      date: '1400/09/09',
    },
    {
      title: 'پیام جدید از منکا',
      date: '1400/09/09',
    },
  ]);
  return (
    <div
      style={{
        borderRadius: '10px',
        padding: '10px',
        margin: '10px',
        backgroundColor: 'white',
        width: '45%',
      }}
      className="w-auto bg-white rounded-lg p-4 m-2

    ">
      <div className="mb-4 min-w-full items-center justify-between">
        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
          پیغام های اخیر
        </h5>
      </div>
      <hr />
      <div className="flow-root">
        {status === 'success' && (
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {messages?.map((item, index) => (
              <ItemInboxHome key={index} title={item.title} date={item.date} />
            ))}
          </ul>
        )}
        {status === 'noMsg' && (
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            <div className="flex items-center justify-between">
              پیامی برای نمایش وجود ندارد
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
