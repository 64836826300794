import React from 'react';

export default function ChildFiledIndex(props) {
  const { children, image, status, location, childId } = props;

  return (
    <div
      onClick={() => {
        window.location.href = `/dashboard/childinfo/${childId}`;
      }}
      className="rounded-lg border-2 border-gray-200 p-4 text-center ml-2 h-32 flex items-center justify-center cursor-pointer">
      <img
        src={image}
        alt="Card Image"
        className="inline-block ml-2  w-16 h-16"
      />
      <h2 className="text-md mt-3 font-semibold text-gray-900 dark:text-white border-t-5 border-gray-500">
        {children}
      </h2>
    </div>
  );
}
