import img1 from '../assets/rooms/1.png';
import img2 from '../assets/rooms/2.png';
import img3 from '../assets/rooms/3.png';
import img4 from '../assets/rooms/4.png';
import img5 from '../assets/rooms/5.png';

const roomType = [
  {
    id: 1,
    title: 'ارتباطات',
    img: img1,
  },
  {
    id: 2,
    title: 'حرکات درشت',
    img: img2,
  },
  {
    id: 3,
    title: 'حرکات ظریف',
    img: img3,
  },
  {
    id: 4,
    title: 'حل مساله',
    img: img4,
  },
  {
    id: 5,
    title: 'مهارت های شخصی و اجتماعی ',
    img: img5,
  },
];

export default roomType;
