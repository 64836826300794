import img1 from '../assets/baby/1.png';
import img2 from '../assets/baby/2.png';
import img3 from '../assets/baby/3.png';
import img4 from '../assets/baby/4.png';
import img5 from '../assets/baby/5.png';
import img6 from '../assets/baby/6.png';
import img7 from '../assets/baby/7.png';
import img8 from '../assets/baby/8.png';
import img9 from '../assets/baby/9.png';
import img10 from '../assets/baby/10.png';
import img11 from '../assets/baby/11.png';
import img12 from '../assets/baby/12.png';
import img13 from '../assets/baby/13.png';
import img14 from '../assets/baby/14.png';
import img15 from '../assets/baby/15.png';
import img16 from '../assets/baby/16.png';
import img17 from '../assets/baby/17.png';
import img18 from '../assets/baby/18.png';
import img19 from '../assets/baby/19.png';
import img20 from '../assets/baby/20.png';
import img21 from '../assets/baby/21.png';
import { max } from 'moment-jalaali';

const ageGroups = [
  { id: 1, title: 'پرسشنامه ۲ ماهگی', img: img1, min: '1', max: '2.30' },
  { id: 2, title: 'پرسشنامه ۴ ماهگی', img: img2, min: '3', max: '4.30' },
  { id: 3, title: 'پرسشنامه ۶ ماهگی', img: img3, min: '5', max: '6.30' },
  { id: 4, title: 'پرسشنامه ۸ ماهگی', img: img4, min: '7', max: '8.30' },
  { id: 5, title: 'پرسشنامه ۹ ماهگی', img: img5, min: '9', max: '9.30' },
  { id: 6, title: 'پرسشنامه ۱۰ ماهگی', img: img6, min: '10', max: '10.30' },
  { id: 7, title: 'پرسشنامه ۱۲ ماهگی', img: img7, min: '11', max: '12.30' },
  { id: 8, title: 'پرسشنامه ۱۴ ماهگی', img: img8, min: '13', max: '14.30' },
  { id: 9, title: 'پرسشنامه ۱۶ ماهگی', img: img9, min: '15', max: '16.30' },
  { id: 10, title: 'پرسشنامه ۱۸ ماهگی', img: img10, min: '17', max: '18.30' },
  { id: 11, title: 'پرسشنامه ۲۰ ماهگی', img: img11, min: '19', max: '20.30' },
  { id: 12, title: 'پرسشنامه ۲۲ ماهگی', img: img12, min: '21', max: '22.30' },
  { id: 13, title: 'پرسشنامه ۲۴ ماهگی', img: img13, min: '23', max: '25.15' },
  { id: 14, title: 'پرسشنامه ۲۷ ماهگی', img: img14, min: '25', max: '28.15' },
  {
    id: 15,
    title: 'پرسشنامه ۳۰ ماهگی',
    img: img15,
    min: '28.16',
    max: '31.15',
  },
  {
    id: 16,
    title: 'پرسشنامه ۳۳ ماهگی',
    img: img16,
    min: '31.16',
    max: '34.15',
  },
  {
    id: 17,
    title: 'پرسشنامه ۳۶ ماهگی',
    img: img17,
    min: '34.16',
    max: '38.30',
  },
  { id: 18, title: 'پرسشنامه ۴۲ ماهگی', img: img18, min: '39', max: '44.30' },
  { id: 19, title: 'پرسشنامه ۴۸ ماهگی', img: img19, min: '45', max: '50.30' },
  { id: 20, title: 'پرسشنامه ۵۴ ماهگی', img: img20, min: '51', max: '56.30' },
  { id: 21, title: 'پرسشنامه ۶۰ ماهگی', img: img21, min: '57', max: '66' },
];

export default ageGroups;
