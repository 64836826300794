import React, { useState, useEffect } from 'react';
import { DatePicker } from 'zaman';
import moment from 'moment-jalaali';

function PersianDatePicker({ initialDate, onChange, label, name, error }) {
  const [date, setDate] = useState();

  useEffect(() => {
    setDate(moment(initialDate));
  }, [initialDate]);

  const handleDateChange = (selectedDate) => {
    const persianDate = moment(selectedDate);
    setDate(persianDate);
    if (onChange) {
      onChange(name, selectedDate.value);
    }
  };

  return (
    <div className="flex flex-col">
      <label className="block mb-3 text-sm font-medium text-gray-700">
        {label}
      </label>
      <DatePicker
        selected={date && date.toDate()}
        onChange={handleDateChange}
        dateFormat="jYYYY/jMM/jDD"
        calendarContainer={moment}
        inputClass="h-10 w-full rounded-md border-1  border-gray-300 p-2 ml-3 mb-2 mt-3"
      />
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
}

export default PersianDatePicker;
