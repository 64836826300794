import api from './api';

const createNewOrder = async (orderData) => {
  try {
    const response = await api.post('/order/addnew', {
      orderData,
    });
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

const getOrders = async (userId) => {
  try {
    const response = await api.get(`/order/all/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export { createNewOrder, getOrders };
