import { Button, Table } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import ChildFiledIndex from '../../../components/ChildFiledIndex';
import { getSingleAsqTest } from '../../../api/getAsqResult';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-jalaali';

function LastResults() {
  const [status, setStatus] = useState('idle');
  const [testData, setTestData] = useState([]);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const parentId = user.userId;
  console.log('parentId', parentId);

  useEffect(() => {
    setStatus('loading');
    getSingleAsqTest(parentId).then((res) => {
      console.log('res', res);
      if (res.score.length === 0) {
        setStatus('noData');

        return;
      }
      setStatus('loaded');
      setTestData(res.score);
    });
  }, []);

  return (
    <div
      style={{
        borderRadius: '10px',
        padding: '10px',

        backgroundColor: 'white',
        marginLeft: '30px',
        marginRight: '10px',
      }}
      className="w-full">
      <div className="mb-4 flex items-center">
        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
          آخرین تست‌های انجام شده
        </h5>
      </div>
      {status === 'noData' && (
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          <div className="flex items-center justify-between">
            تستی برای نمایش وجود ندارد
          </div>
        </div>
      )}
      {status === 'loaded' && (
        <Table hoverable>
          <Table.Head>
            <Table.HeadCell>عنوان تست</Table.HeadCell>
            <Table.HeadCell>تاریخ</Table.HeadCell>
            <Table.HeadCell>نام فرزند</Table.HeadCell>

            <Table.HeadCell>
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {testData.map((item, index) => (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {item.roomType}
                </Table.Cell>
                <Table.Cell>
                  {moment(item.date).format('jYYYY/jMM/jDD')}
                </Table.Cell>

                <Table.Cell>{item.childFirstName}</Table.Cell>

                <Table.Cell>
                  {item.status === 'pending' ? (
                    <Button
                      size="sm"
                      className="mr-2"
                      // onClick={() => alert('edit')}
                    >
                      در انتظار بررسی
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      className="mr-2"
                      onClick={() => alert('edit')}>
                      مشاهده نتیجه
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
}

export default LastResults;
