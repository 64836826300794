import axios from 'axios';
import Alertmodal from '../components/Alertmodal';

const api = axios.create({
  baseURL: 'https://menka-backend.azurewebsites.net/',
  // office local
  //baseURL: 'http://localhost:8082',
  // home local
  //baseURL: 'http://192.168.1.55:8080',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to add auth key if provided
api.interceptors.request.use(
  (config) => {
    if (config.authKey) {
      config.headers['Authorization'] = `Bearer ${config.authKey}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor to handle errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      // Network error

      alert('Network error');
    } else {
      // Other errors
      console.log('Network error', error.response.data.error);
      alert('Network error');
    }
    return Promise.reject(error);
  }
);

export default api;
