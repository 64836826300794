import React, { Children } from 'react';
import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
function Alertmodal({ buttons, children, type }) {
  const [openModal, setOpenModal] = useState(true);

  return (
    <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          {/* <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" /> */}
          <h3 className="mb-5 text-xs font-normal text-gray-500 dark:text-gray-400">
            {children}
          </h3>
          <div className="flex justify-center gap-4">
            {buttons?.map((button, i) => (
              <Button
                key={i}
                style={{ background: '#3F5F90' }}
                className="w-full text-white"
                onClick={() => button.onClick()}>
                {button.title}
              </Button>
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Alertmodal;
