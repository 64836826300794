const attachmentTest = [
  {
    id: 1,
    question: 'فرزندم با دیگر کودکان به راحتی بازی می کند.',
  },
  {
    id: 2,
    question:
      'فرزندم به آسانی با کودکان دیگر دوست می شود و از این لحاظ احساس راحتی می کند.',
  },
  {
    id: 3,
    question: 'فرزندم همانند کودکان هم سن و سال خود صحبت می کند.',
  },
  {
    id: 4,
    question: 'فرزندم با حیوانات با مهربانی و آرام رفتار می کند.',
  },
  {
    id: 5,
    question:
      'فرزندم به اشتباهاتش پی می برد و رفتارهایی که نتایج منفی بدنبال دارد را انجام نمی دهد.',
  },
  {
    id: 6,
    question: 'فرزندم آنچه را که به او گفته می شود را درک می کند.',
  },
  {
    id: 7,
    question: 'اگر کارها طبق روال پیش نروند، فرزندم دچار آشفتگی می گردد.',
  },
  {
    id: 8,
    question: 'فرزندم بیش از حد ناله و زاری می کند.',
  },
  {
    id: 9,
    question: 'فرزندم دوست ندارد جدا از من باشد مگر زمان هایی که خودش یخواهد.',
  },
  {
    id: 10,
    question: 'فرزندم بیش از حد به افراد می چسبد.',
  },
  {
    id: 11,
    question:
      'به نظر می رسد فرزندم با دیدن آتش و خون بیش از حد شگفت زده و هیجان زده می شود.',
  },
  {
    id: 12,
    question:
      'فرزندم به اشیاء متعلق به دیگران آسیب زده و آنها را تخریب می کند.',
  },
  {
    id: 13,
    question: 'فرزندم وسایل مربوط به خودش را شکسته یا به آنها آسیب می زند.',
  },
  {
    id: 14,
    question:
      'فرزندم برخی چیزها را سرقت می کند و به نظر نمی رسد در این خصوص احساس بدی داشته باشد.',
  },
  {
    id: 15,
    question:
      'فرزندم کودکان دیگر را اذیت کرده و به آنها صدمه می زند و نسبت به سایر کودکان بی رحم است.',
  },
  {
    id: 16,
    question:
      'فرزندم به جمع کردن خوراکی ها علاقه مند بوده و عادات غذایی غیر معمول (به عنوان مثال؛ خوردن کاغذ، خاک، مدفوع و ...) دارد.',
  },
  {
    id: 17,
    question:
      'زمانی که فرزندم آشفته است معمولا اجازه نمی دهد افراد آشنا او را آرام کنند اما برای آرام شدن به سمت غریبه ها می رود.',
  },
  {
    id: 18,
    question:
      'زمانی که فرزندم آسیب می بیند به هیچکس اجازه نمی دهد تا او را آرام کند.',
  },
  {
    id: 19,
    question: 'زمانی که فرزندم درد دارد سهی می کند آن را نشان ندهد.',
  },
  {
    id: 20,
    question:
      'فرزندم به دیگران می گوید که من او را اذیت می کنم در حالی که چنین چیزی صحت ندارد.',
  },
];

export default attachmentTest;
