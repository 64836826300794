import { Radio } from 'flowbite-react';
import React from 'react';

function Radionbuttons({ value, checked, onChange, label, style }) {
  return (
    <div
      dir="rtl"
      style={{
        width: '100px',
      }}
      className={`flex flex-${style} w-full border border-1
       mt-4 border-gray-200 border-opacity-60 rounded-md p-2  text-right`}>
      <Radio
        type="radio"
        name="radio-10"
        className="w-4 ml-3 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <label className="label cursor-pointer">
        <span className="label-text text-main">{label}</span>
      </label>
    </div>
  );
}

export default Radionbuttons;
