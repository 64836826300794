import React from 'react';
import roomType from '../../../data/roomType';
import { useNavigate } from 'react-router-dom';

function Rooms({
  handleStoreValueOfRoomType,
  selectedRoomType,
  childId,
  parentId,
  childFirstName,
  childLastName,
}) {
  const navigate = useNavigate();

  const handleClick = (value) => {
    try {
      localStorage.setItem('roomType', JSON.stringify(value));
      navigate(`/dashboard/test/3/start`, {
        state: {
          childId: childId,
          parentId: parentId,
          childFirstName: childFirstName,
          childLastName: childLastName,
        },
      });
    } catch (error) {
      console.error('Error:', error);
    }
    handleStoreValueOfRoomType(value);
  };

  return (
    <div className="mr-3 mt-3 p-6 bg-white border border-gray-200 rounded-md flex flex-row w-full ml-3 h-auto">
      <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 my-2 w-full">
        {roomType.map((room, i) => (
          <div
            key={i}
            onClick={() => handleClick(room.title)}
            className="mb-3 flex flex-col items-center justify-center h-72  cursor-pointer hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-48 h-48 mb-3">
              <img
                src={room.img}
                alt={room.title}
                className="object-contain w-full h-full"
              />
            </div>
            <h5 className="text-lg font-bold text-gray-900 dark:text-white mb-2">
              {room.title}
            </h5>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Rooms;
