import React, { useEffect, useState } from 'react';
import tick from '../../../../assets/icons/clipboard-tick.svg';
import clipboardText from '../../../../assets/icons/clipboard-text.svg';
import timer from '../../../../assets/icons/timer.svg';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkIfChildRegistered } from '../../../../api/normalTestCheck';
import Alertmodal from '../../../../components/Alertmodal';
import Radionbuttons from '../../../../components/RadioButtonMenka';
import { getChildren } from '../../../../api/ChildStoreServer';
import { Alert, Radio } from 'flowbite-react';
import { createNewOrder } from '../../../../api/createOrder';
import Arrow from '../../../../assets/arrow.svg';
import Tick from '../../../../assets/icons/shield-tick.svg';
function SingleQIndex() {
  //get id from react router dom params
  //const { id } = useParams();

  const [parentId, setParentId] = useState(
    useSelector((state) => state.user.userId)
  );
  const [isChildRegistered, setIsChildRegistered] = useState('idle');
  console.log('isChildRegistered:', isChildRegistered);
  const [checkPaid, setchaeckPaid] = useState('idle');
  const [children, setChildren] = useState([]);
  const [selectedChild, setSelectedChild] = useState({});
  console.log('selectedChild:', selectedChild);
  //store selected child id in local storage

  localStorage.setItem('selectedChild', JSON.stringify(selectedChild));

  const handleSelectChild = (child) => {
    console.log('child:', child);
    setSelectedChild(child);
    localStorage.setItem('selectedChild', JSON.stringify(child));
    if (id === 3) window.location.href = `/dashboard/asq`;
    else window.location.href = `/dashboard/initialtests/${id}/start`;
  };

  //get from react router dom state
  const { title, id, description, warning } = useLocation().state;

  const handleClick = async () => {
    console.log('parentId:', parentId);
    try {
      const res = await checkIfChildRegistered(parentId);
      console.log('res:', res);
      if (!res) {
        setIsChildRegistered('notRegistered');
      } else {
        setIsChildRegistered('registered');
        try {
          const list = await getChildren(parentId);
          setChildren(list);
        } catch (error) {
          console.error('Error:', error);
        }
      }
      //setchaeckPaid('unPaid');
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div
      style={{
        padding: '10px',
        margin: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
      className="flex flex-row w-full  bg-gray-100 dark:bg-gray-800">
      <div className=" bg-main border border-grey-200 rounded-md w-full  py-3 px-3">
        <div className="mb-3  rounded-md">
          <h5
            className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white 
           flex flex-row
          ">
            <img
              onClick={() => {
                window.location.href = '/dashboard/questionnaire';
              }}
              src={Arrow}
              alt="Card Image"
              className="object-contain"
            />
            {title}{' '}
          </h5>
        </div>
        <hr />
        <div
          style={{
            padding: '10px',
            margin: '10px',
            backgroundColor: 'white',
            borderRadius: '10px',
          }}
          className="flex flex-row my-2">
          <div className="mb-3 flex w-full   rounded-md mt-3 mb-3">
            {id !== 3 ? (
              <h5 className="mb-2 text-sm font-bold tracking-tight text-gray-900 dark:text-white">
                {description}
              </h5>
            ) : (
              <Alert
                style={{
                  backgroundColor: '#F9F9FF',
                }}
                className="flex flex-col w-full justify-start  mt-3 border rounded-md ">
                <div className="mb-3 flex  h-72 rounded-md ">
                  <img src={Tick} alt="Card Image" className="object-contain" />{' '}
                  <h5 className="mb-3 mr-2 text-sm font-bold tracking-tight text-gray-900 dark:text-white">
                    {description}
                  </h5>
                </div>
                <br />
                <div className="mb-3 flex  h-72 rounded-md">
                  <img src={Tick} alt="Card Image" className="object-contain" />{' '}
                  <h5 className="mb-3 mr-2 text-sm font-bold tracking-tight text-gray-900 dark:text-white">
                    {warning}
                  </h5>
                </div>
              </Alert>
            )}
          </div>
        </div>
        <hr />
        <br />
        <div className="flex flex-rows-3 gap-2 h-80">
          <div className="flex flex-col border border-grey-200 rounded-md items-center h-72  w-full">
            <div className="mb-3 flex  h-72 rounded-md">
              <img
                src={tick}
                alt="Card Image"
                className="
                object-contain
              "
              />
            </div>
            <div className="mb-3 flex  rounded-md">تعداد انجام تست</div>
            <div className="mb-3 flex rounded-md">۸۹۵</div>
          </div>
          <div className="flex flex-col border border-grey-200 rounded-md items-center  w-full">
            <div className="mb-3 flex w-auto h-48 rounded-md">
              <img
                src={clipboardText}
                alt="Card Image"
                className="
                object-contain
              "
              />
            </div>
            <div className="mb-3 flex  rounded-md">تعداد سوالات</div>
            <div className="mb-3 flex rounded-md">۳۰ سوال</div>
          </div>
          <div className="flex flex-col border border-grey-200 rounded-md items-center  w-full h-auto">
            <div className="mb-3 flex w-auto h-48 rounded-md mr-3 ml-3">
              <img
                src={timer}
                alt="Card Image"
                className="
                object-contain
              "
              />
            </div>
            <div className="mb-3 flex  rounded-md">زمان تقریبی </div>
            <div className="mb-3 flex rounded-md">10 دقیقه</div>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: '10px',
          margin: '10px',
          backgroundColor: 'white',
          borderRadius: '10px',
        }}
        className="rounded-md p-2 w-80 h-auto  bg-white border border-grey-200 dark:bg-gray-800 dark:border-gray-800 dark:text-white
        
        ">
        <div className="flex justify-between font-bold border-grey-200">
          <h3>هزینه تست</h3>
          <h2
            style={{
              color: '#3F5F90',
              fontWeight: 'bold',
              fontSize: '1.1rem',
            }}
            className="mb-2 text-sm font-bold tracking-tight">
            {id === 3 ? '۲۹۰،۰۰۰ تومان' : '   رایگان'}
          </h2>
        </div>
        <hr />
        <div
          className="mt-3  rounded-md 
        ">
          <button
            onClick={handleClick}
            style={{ backgroundColor: '#3f51b5' }}
            className="bg-main text-white w-full rounded-md p-2 justify-center items-center">
            شروع تست
          </button>
        </div>
        {isChildRegistered === 'notRegistered' && (
          <Alertmodal
            type="danger"
            buttons={[
              {
                title: 'بله',
                onClick: () => {
                  window.location.href = '/dashboard/addnew';
                },
              },
            ]}>
            برای انجام این تست ابتدا باید اطلاعات فرزند خود را وارد کنید
          </Alertmodal>
        )}

        {checkPaid === 'unPaid' && (
          <Alertmodal
            type="danger"
            buttons={[
              {
                title: 'بله',
                onClick: () => {
                  createNewOrder(title, parentId, 290000);
                  window.location.href = '/dashboard/payment';
                },
              },
            ]}>
            نام تست : {title}
            هزینه تست : ۲۹۰،۰۰۰ تومان برای انجام این تست ابتدا باید هزینه تست را
            پرداخت کنید
          </Alertmodal>
        )}
        {isChildRegistered === 'registered' && (
          <Alertmodal
            type="danger"
            buttons={[
              {
                title: 'بله',
                onClick: () => {
                  window.location.href = '/dashboard/addnew';
                },
              },
            ]}>
            فرزند مورد نظر را جهت انجام تست ASQ انتخاب کنید
            {children?.map((child) => (
              <div
                dir="rtl"
                className={`flex flex-row w-full border border-1 
               mt-4 border-gray-200 border-opacity-60 rounded-md p-2  text-right`}>
                <Radio
                  type="radio"
                  name="radio-10"
                  className="w-4 ml-3 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  checked={selectedChild === child}
                  value={child}
                  onChange={() => handleSelectChild(child)}
                />
                <label className="label cursor-pointer">
                  <span className="label-text text-main">
                    {child.firstName}
                  </span>
                </label>
              </div>
            ))}
          </Alertmodal>
        )}
      </div>
    </div>
  );
}

export default SingleQIndex;
