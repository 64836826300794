import React from 'react';

function RadionbuttonComponent({ label, options, name, onChange, error }) {
  return (
    <div className="w-full flex flex-col mt-3">
      <label className="w-full mb-3 text-sm font-medium text-gray-700">
        {label}
      </label>
      <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        {options.map((option, i) => (
          <li
            key={i}
            className="w-full dark:border-gray-600 justify-start border-gray-300 border-b dark:border-b-0 dark:border-t-0 dark:border-l-0 dark:border-r-0 dark:hover:bg-gray-600 dark:hover:text-white dark:hover:border-gray-600 dark:hover:border-b-0 dark:hover:border-t-0 dark:hover:border-l-0 dark:hover:border-r-0 dark:focus:bg-gray-600 dark:focus:text-white dark:focus:border-gray-600 dark:focus:border-b-0 dark:focus:border-t-0 dark:focus:border-l-0 dark:focus:border-r-0 dark:focus:ring-2 dark:focus:ring-offset-gray-700 dark:focus:ring-offset-0 dark:focus:ring-blue-500 dark:focus:ring-offset-blue-500
          ">
            <div className="flex items-center ps-3">
              <input
                onChange={(e) => onChange(name, e.target.value)}
                id="horizontal-list-radio-passport"
                type="radio"
                value={option.value}
                name={name}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="horizontal-list-radio-passport"
                className="w-full py-3 ms-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                {option.label}
              </label>
            </div>
          </li>
        ))}
      </ul>
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
}

export default RadionbuttonComponent;
