import React from 'react';

function UnderConstruction() {
  return (
    <div>
      <h1>Under Construction</h1>
    </div>
  );
}

export default UnderConstruction;
