import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: '',
  name: '',
  phone: '',
  avatar: '',
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log('Setting user...', action.payload);
      state.userId = action.payload.userId;
      state.name = action.payload.name;
      state.phone = action.payload.phone;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.avatar = action.payload.avatar;
    },
    removeUser: (state) => {
      console.log('Removing user...');
      state.userId = '';
      state.name = '';
      state.phone = '';
      state.isLoggedIn = false;
      state.avatar = '';
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
