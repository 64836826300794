const convertToPersianNumber = (number) => {
  // Mapping of English digits to Persian digits
  const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

  // Convert the number to a string and map each character to its Persian equivalent
  return number
    .toString()
    .split('')
    .map((digit) => (/[0-9]/.test(digit) ? persianDigits[digit] : digit))
    .join('');
};
const convertToEnglishNumber = (number) => {
  console.log('number in convertToEnglishNumber', number);

  // Convert the number to a string and map each character to its English equivalent
  return number
    .toString()
    .split('')
    .map((digit) => {
      // Check if the character is a Persian digit
      if (/[۰-۹]/.test(digit)) {
        // Convert Persian digit to English digit
        return String.fromCharCode(digit.charCodeAt(0) - 0x06f0 + 48);
      }
      return digit;
    })
    .join('');
};

export { convertToPersianNumber, convertToEnglishNumber };
