import React, { useEffect, useState } from 'react';
import Boy from '../../../assets/boy.png';
import Girl from '../../../assets/girl.png';
import Add from '../../../assets/Add.png';
import ChildFiledIndex from '../../../components/ChildFiledIndex';
import { Link } from 'react-router-dom';
import { getChildren } from '../../../api/ChildStoreServer';
import { useSelector } from 'react-redux';

function ChildrenSection({ location }) {
  const [children, setChildren] = useState([]);
  const [status, setStatus] = useState('loading');
  const parentId = useSelector((state) => state.user.userId);
  useEffect(() => {
    const fetchChildren = async () => {
      const response = await getChildren(parentId);
      setStatus('loaded');
      setChildren(response);
    };
    fetchChildren();
  }, [parentId]);

  return (
    <div
      style={{
        borderRadius: '10px',

        padding: '10px',
        margin: '10px',
        backgroundColor: 'white',
      }}
      className={location === 'main' ? 'w-1/2' : 'w-full '}>
      <div className="mb-4 flex items-center">
        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white ">
          فرزندان من
        </h5>
      </div>
      <hr />

      {status === 'loaded' && (
        <div
          className={`flex flex-cols-${location === 'main' ? '2' : '3'}
         mt-3
      `}>
          {children?.map((child, index) => {
            return (
              <ChildFiledIndex
                gender={child.gender}
                childId={child.childId}
                location={location}
                key={index}
                children={child.firstName}
                image={child.gender === 'boy' ? Boy : Girl}
              />
            );
          })}
          <div
            className={`rounded-lg h-32 w-32 border-2 border-gray-200 p-4 text-center flex items-center justify-center`}>
            <img
              src={Add}
              alt="Card Image"
              className="inline-block w-5 h-5 mt-2 my-2"
            />
            <Link
              to="/dashboard/addnew"
              className="text-blue-500 font-bold text-lg">
              <h2 className="text-xs h-32 mt-2 m-2 font-semibold text-gray-900 dark:text-white border-t-5 border-gray-500">
                افزودن فرزند
              </h2>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChildrenSection;
