import asqs from '../data/asqs';

const getAsqs = async () => {
  //get ageGroup from asqs array

  const roomType = asqs.map((asq) => asq.roomType);
  //get unique ageGroup
  const uniqueRoomType = [...new Set(roomType)];
  //get all asqs

  return uniqueRoomType;
};

export default getAsqs;
