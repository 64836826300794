import { getChildren } from './ChildStoreServer';

const checkIfChildRegistered = async (parentId) => {
  try {
    const children = await getChildren(parentId);
    console.log('children:', children);
    if (children.length >= 1) {
      console.log('children.length:', children.length);
      return true;
    }

    return false;
  } catch (error) {
    console.error('Error:', error);
  }
};

export { checkIfChildRegistered };
