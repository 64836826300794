import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrders } from '../../../api/orders';
import moment from 'moment-jalaali';
import { Button } from 'flowbite-react';

function PaymentHistoryIndex() {
  const userId = useSelector((state) => state.user.userId);
  const [paymentHistory, setPaymentHistory] = useState([]);
  console.log(paymentHistory);
  const getPaymentHistory = async (userId) => {
    try {
      const response = await getOrders(userId);
      setPaymentHistory(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getPaymentHistory(userId);
  }, [userId]);

  const handlePay = async (order) => {};

  return (
    <div
      style={{
        padding: '10px',
        margin: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
        width: '100%',
      }}
      className="flex flex-row w-full  bg-gray-100 dark:bg-gray-800">
      <div className="w-full">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                شماره سفارش
              </th>
              <th scope="col" className="px-6 py-3">
                عنوان سفارش
              </th>
              <th scope="col" className="px-6 py-3">
                تاریخ
              </th>
              <th scope="col" className="px-6 py-3">
                مبلغ پرداختی
              </th>
              <th scope="col" className="px-6 py-3">
                وضعیت
              </th>

              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {paymentHistory.map((order, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                        {order.orderNumber}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                        {order.orderItems}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                        {moment(order.orderDate).format('jYYYY/jMM/jDD')}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                        {order.orderTotal}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="ml-4">
                      <div
                        className={`text-sm font-bold  ${
                          order.orderStatus === 'Paid'
                            ? 'text-green-500 dark:text-green-400 '
                            : order.orderStatus === 'Failed'
                            ? 'text-red-500 dark:text-red-400 font-bold'
                            : 'text-yellow-500 dark:text-yellow-400 font-bold'
                        }`}>
                        {order.orderStatus === 'Paid'
                          ? 'پرداخت شده'
                          : order.orderStatus === 'Failed'
                          ? 'پرداخت ناموفق'
                          : 'در انتظار پرداخت'}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="ml-4">
                      {order.orderStatus === 'pending' && (
                        <Button
                          style={{
                            backgroundColor: 'green',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '5px',
                          }}>
                          پرداخت
                        </Button>
                      )}
                      {order.orderStatus === 'paid' && (
                        <Button
                          style={{
                            backgroundColor: 'green',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '5px',
                          }}>
                          دانلود
                        </Button>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PaymentHistoryIndex;
